import styled from 'styled-components';
import mq from "../media-queries"

export const SiteBorderStyles = styled.div`
  padding: 0px;
  margin: 0 4%;

  ${mq.md} {
    padding: 5px;
    padding: clamp(5px,3vw,30px);
    margin: 0 8%;
  }

`;

export const Section = styled(SiteBorderStyles)`

`;

export const SectionFullWidth = styled.section`

`;

export const HeroSmallStyles = styled.div`
  h1, h2 {
    color: var(--white);
    text-align: center;
    letter-spacing: .04em;
    text-transform: uppercase;
  }

  h1 {
    font-weight: bold;

  }

  h2 {
    margin-top: 10px;
    font-size: 1.4rem;
  }
`

export const HrStyles = styled.hr`
    height: 3px;
    background-color: var(--lightgrey);
    width:100%;
`

export const HR = styled(HrStyles)`
  width: 150px;
`
