import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { Section, HeroSmallStyles } from '../../styles/common/Common'
import Img from 'gatsby-image';

const HeroWrapper = styled.section`
  width: 100%;
  background-color: rgb(255, 255, 255);
  background-image: linear-gradient(
    117deg,
    rgba(218, 218, 218, 0.76) 56%,
    rgba(149, 27, 129, 0.66) 100%
  ),
  url(${props => props.background});

  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;

  height: calc(100vh - 165px);
`
const HeroWrapperSmall = styled.section`
  width: 100%;
  background-color: rgb(255, 255, 255);
  position: relative;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
  background: -webkit-linear-gradient(left, rgb(218, 218, 218) 0%, rgb(149, 27, 129) 83%);
  height: inherit;
`

const Hero = ({ children, slug, small }) => {

  const data = useStaticQuery(graphql`
    query Hero2Query {
      allSanityPageContent {
        edges {
          node {
            slug {
            current
          }
          heroImage {
            asset {
              url
            }
          }
          }
        }
      }
    }
  `)

  const image = data.allSanityPageContent.edges.find(
    edge => edge.node.slug.current === slug
  )
  if (!image && !small) {
    return null
  }

  if (small) {
    return (
      <>
        {small && <HeroWrapperSmall>
          <Section>
            <HeroSmallStyles>
              {children}
            </HeroSmallStyles>
          </Section>
        </HeroWrapperSmall>
        }
      </>
    )
  }
  const styledBackground = image.node.heroImage.asset.url ? image.node.heroImage.asset.url : ' ';
  return (
    <>

      {!small && <HeroWrapper background={styledBackground}>{children}</HeroWrapper>}
      {small && <HeroWrapperSmall>
        <Section>
          <HeroSmallStyles>
            {children}
          </HeroSmallStyles>
        </Section>
      </HeroWrapperSmall>
      }
    </>
  )
}

export default Hero